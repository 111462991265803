import { ThinPerson } from "./client";
import { Domain } from "./types";

export type { ThinPerson };

export class PeopleDomain extends Domain {
  unsubscribe = (email: string, type: string) => this.api.people.unsubscribe({ email, type });

  search = (query: string, limit: number = 20) => this.api.people.search({ q: query, limit });
}
